import React from "react";
import seven_image from "../../../assets/story that grow/images.png";
import "./style.css";
import Button from "../landing-page-button";

const ExploreStories = ({exploreStoriesRef}) => {
  return (
    <div>
      <div className="category-container" ref={exploreStoriesRef}>
        <div className="category-wrap">
          <div className="category-content-wrap" data-aos="zoom-in-up">
            <div className="category-content">
              {/* <p className="category-hd">CATEGORY</p> */}
              <p className="category-content-hd">Stories that Grow with kids</p>
              <p className="category-content-text">
                FictionGenie, an Al-powered platform not just provide parents
                with assessment of their children's reading advancement, but
                also offers insight to enhance their creative skills.
              </p>
              <Button title="Explore Stories"/>
            </div>
          </div>
          <div className="category-img-wrap" data-aos="zoom-in">
            <img src={seven_image} className="category-img" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExploreStories;
