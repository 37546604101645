import React from "react";
import founder from "../../../assets/founder/image.png";
import "./style.css";

const OurFounder = () => {
  return (
    <div>
      <div className="our-founder-container">
        <div className="our-founder-wrap">
          <div className="our-founder-content-wrap" data-aos="zoom-in-up">
            <p className="our-founder-content-hd">A note from our founder</p>
            <p className="our-founder-text">
              As the founder of this platform, I am over the moon to welcome you
              to our community of budding storytellers and writer.I may be
              baised, but I think we have the coolest and the most creative
              bunch of kids around.My hope is that FictionGenie will not only
              help children become better story teller but also instill a
              lifelong love of reading and storytelling. I am grateful to you
              all on this journey with me, I can't wait to see the amazing
              stories that your children will create.Thank you for being a part
              of the FictionGenie community, and let's ignite our children's
              creativity together!
            </p>
            <div className="founder-name-res-container">
              <p className="res-founder-name founder-name">Jamal Nasir</p>
              <p className="res-founder-name founder-name">Founder and CEO</p>
            </div>
          </div>
          <div className="our-founder-img-container" data-aos="zoom-in">
            <img src={founder} className="our-founder-img" />
          </div>
        </div>
        <div className="founder-name-container" data-aos="zoom-in-up">
          <p className="founder-name">Jamal Nasir</p>
          <p className="founder-name">Founder and CEO</p>
        </div>
      </div>
    </div>
  );
};
export default OurFounder;
