import React from "react";
import sixth_girl_image from "../../../assets/listen on break/image.png";
import "./style.css";
import Button from "../landing-page-button";

const ListenReading = () => {
  return (
    <div>
      <div className="listening-container">
        <div className="listening-wrap">
            <div className="listening-img-wrap" data-aos="flip-up">
              <img className="listening-girl-img" src={sixth_girl_image} />
            </div>
          <div className="listening-content-container" data-aos="flip-down">
            <div className="listening-content-wrap">
              <p className="listen-hd-wrap">Listen When Reading's on Break</p>
              <p className="listen-text-wrap">
                Discover the benefits of Ai technology in language learning with
                FictionGenie. Kid can read captivating stories in any language
                of their choice and explore new horizons of culture and
                imagination
              </p>
              <Button title="Learn More"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListenReading;
