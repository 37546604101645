import React from "react";
import FictionGenieLogo from "../../../assets/logo/logo-Icon.png";
import "./style.css";

const Logo = ({ width, aifontsize, fictiongeniesize, padding }) => {
  return (
    <div>
      <div className="logo-hd-wrap" style={{ padding: padding }}>
        {/* <img src={FictionGenieLogo} className="logo" alt="" /> */}
        <img
          src={FictionGenieLogo}
          alt=""
          className="header-logo"
          style={{ width: width }}
        />
        <div className="hd-wrap">
          <p
            className="fiction-genie-hd"
            style={{ fontSize: fictiongeniesize }}
          >
            FictionGenie
          </p>
          <p className="ai-powered-hd" style={{ fontSize: aifontsize }}>
            AI Powered Stories For Kids
          </p>
        </div>
      </div>
    </div>
  );
};

export default Logo;
