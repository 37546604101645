import React, { useRef, useState } from "react";
import headerLightCircle from "../../../assets/header/header_light_cirlce.png";
import headerHoleImage from "../../../assets/header/hole_header.png";
import lightHole from "../../../assets/header/light-hole.png";
import bg_gray_header from "../../../assets/header/orignal-dot.png";
import HeaderMainImg from "../../../assets/header/headermain-img.png";
import { Link } from "react-router-dom";
import "./style.css";
import Button from "../landing-page-button";
import { TfiMenu } from "react-icons/tfi";
import FictionGenieLogo from "../../../assets/logo/logo-Icon-White.png";
import { RxCross1 } from "react-icons/rx";
import Logo from "../logoComponent/index";
const Header = ({ onLink, headerRef, showNav, toggleNav }) => {
  return (
    <div>
      <div className="header" ref={headerRef}>
        <div className="nav-bar-wrap">
        <Logo/>
          <div className="header-links-wrap">
            <Link onClick={() => onLink("aboutUsRef")} className="header-link">
              About us
            </Link>
            <Link
              onClick={() => onLink("categoriesRef")}
              className="header-link"
            >
              Categories
            </Link>
            <Link
              onClick={() => onLink("learnMoreRef")}
              className="header-link"
            >
              Learn more
            </Link>
            <Link
              onClick={() => onLink("exploreStoriesRef")}
              className="header-link"
            >
              Explore stories
            </Link>
          </div>
        </div>
        <div className={`nav-bar-wrap-responsive ${showNav ? "show" : ""}`}>
         <Logo/>
          <div
            className={`header-links-container-res ${showNav ? "show" : ""}`}
          >
            <Logo width={"60px"} aifontsize={"15px"} fictiongeniesize={"20px"} padding={"10px"}  />
            <div className="nav-bar-links-wrap">
              <Link
                onClick={() => {
                  onLink("aboutUsRef");
                  toggleNav();
                }}
                className="header-link-res"
              >
                About us
              </Link>
              <Link
                onClick={() => {
                  onLink("categoriesRef");
                  toggleNav();
                }}
                className="header-link-res"
              >
                Categories
              </Link>
              <Link
                onClick={() => {
                  onLink("learnMoreRef");
                  toggleNav();
                }}
                className="header-link-res"
              >
                Learn more
              </Link>
              <Link
                onClick={() => {
                  onLink("exploreStoriesRef");
                  toggleNav();
                }}
                className="header-link-res"
              >
                Explore stories
              </Link>
            </div>
            <div className="close-icon" onClick={toggleNav}>
            <RxCross1 className="cross-icon" />
            </div>
          </div>
          <div className="mobile-icon" onClick={toggleNav}>
            <TfiMenu />
          </div>
        </div>
        <div className={`${showNav === true ? "blur" : ""}`}>
          <div className={`imgs-container `}>
            <img className="left-purpule-donut" src={headerHoleImage} />
            <img className="left-purpule-donut-shadow" src={lightHole} />
            <img className="white-dottet" src={bg_gray_header} />
            <img className="lg-purple-circle" src={headerLightCircle} />
          </div>
          <div className="header-content-img-main-container">
            <div className="header-content-img-container">
              <div className="header-content-wrap" data-aos="fade-up">
                <div className="content-hd-wrap">
                  <p className="content-hd">
                    Connecting Kids with Stories and AI
                  </p>
                  <p className="content-wrap">
                    Connect your kids with stories from AI-powered platform with
                    illustrations and feedback that helps to improve their
                    reading and creativity skills
                  </p>
                  <Button title="Learn More" />
                </div>
              </div>
              <div className="header-main-img-container" data-aos="fade-down">
                <img src={HeaderMainImg} className="header-main-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
