import React, { useState } from "react";
import "./style.css";
import { FaPlus } from "react-icons/fa";
import { RxDash } from "react-icons/rx";
import FaqsImg from "../../../assets/Faqs/Faqs Img.jpeg";
import headerHoleImage from "../../../assets/header/hole_header.png";
import lightHole from "../../../assets/header/light-hole.png";
import bg_gray_header from "../../../assets/header/orignal-dot.png";


const faqsData = [
  {
    question: "What is our Aim?",
    answer:
      "Our aim is to assist parents in enhancing and tracking their children's creativity, writing abilities, and passion for storytelling using our entertaining and interactive platform, while leveraging AI-powered feedback to provide personalized guidance and support.",
  },
  {
    question: "Who is our Target Audience?",
    answer:
      "This platform is primarily designed for parents who are ambitious about improving their child's creativity and reading skills. FictionGenie also provides assessment to parents about how their kid is doing.",
  },
  {
    question: "How is our Platform Interactive?",
    answer:
      "Our AI-powered feedback system is like having a personal coach, always there to give constructive feedback and help children improve their creative skills.",
  },
  {
    question: "How much does it cost?",
    answer:
      "So, we're keeping it really simple: sign up for free, and enjoy all the reading and storytelling goodness that we have to offer for one month. If you are amazed by what we offer, you might want to take advantage of our Basic or Premium subscription plans.",
  },
];

const Faqs = () => {
  const [isActive, setIsActive] = useState(null);

  const toggleActive = (index) => {
    if (isActive === index) {
      setIsActive(null);
    } else {
      setIsActive(index);
    }
  };

  return (
    <div>
      <div className="faqs-main-container">
        <div>
          <img className="left-purpule-donut faqs-donuts" src={headerHoleImage} />
          <img className="left-purpule-donut-shadow faqs-donuts" src={lightHole} />
          <img className="white-dottet faqs-dotted" src={bg_gray_header} />
        </div>
        <div className="faqs-hd-wrap">
          <p className="faqs-hd" data-aos="fade-down">
            FAQ's
          </p>
        </div>
        <div className="faqs-container">
          <div className="faqs-content-container">
            {faqsData.map((faq, index) => (
              <div
                key={index}
                className="faqs-content-wrap"
                data-aos="fade-down"
              >
                <div className="faqs-content-hd-icon-wrap">
                  <p className="faqs-content-hd">{faq.question}</p>
                  <div>
                    {isActive !== index && (
                      <FaPlus
                        className="faqs-icons"
                        onClick={() => toggleActive(index)}
                      />
                    )}
                    {isActive === index && (
                      <RxDash
                        className="faqs-icons faqs-minus-icon"
                        onClick={() => toggleActive(index)}
                      />
                    )}
                  </div>
                </div>
                <p
                  className={`faqs-text ${isActive === index ? "active" : ""} `}
                >
                  {faq.answer}
                </p>
              </div>
            ))}
          </div>
          <div className="faqs-img-container" data-aos="fade-up">
            <img src={FaqsImg} alt="" className="faqs-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
