import React, { useEffect, useState } from "react";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";
import FictionGenieLogo from "../../../assets/logo/logo-Icon-Purple.png"
import "./style.css";
import Testimonial from "./Testimonial/Testimonial";
import Logo from "../logoComponent";

const Footer = ({ onLink }) => {
  const currentYear = new Date().getFullYear();
 
  return (
    <div className="footer-wrap" data-aos="fade-down">
      <Testimonial />
      <div className="footer-content-container">
       <Logo/>
        <div className="footer-links-wrap">
          <p onClick={() => onLink("headerRef")} className="footer-link">
            Home
          </p>
          <p onClick={() => onLink("aboutUsRef")} className="footer-link">
            About us
          </p>
          <p onClick={() => onLink("categoriesRef")} className="footer-link">
            Categories
          </p>
          <p onClick={() => onLink("learnMoreRef")} className="footer-link">
            LearnMore
          </p>
          <p
            onClick={() => onLink("exploreStoriesRef")}
            className="footer-link"
          >
            Explore Stories
          </p>
        </div>
        <div className="social-media-all-icon-container">
          <div className="facebook-icon-wrap">
            <FaFacebookF className="facebook-icon" />
          </div>
          <div className="social-media-icon-wrap">
            <FaTwitter className="social-media-icon" />
          </div>
          <div className="social-media-icon-wrap">
            <FaYoutube className="social-media-icon" />
          </div>
          <div className="social-media-icon-wrap">
            <FaLinkedinIn className="social-media-icon" />
          </div>
        </div>
      </div>
      <div className="copyright-text-wrap">
        <p className="copyright-text">
          Copyright <FaRegCopyright /> FictionGenie {currentYear}
        </p>
      </div>
    </div>
  );
};
export default Footer;
