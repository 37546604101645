import React, { useEffect, useState } from "react";
import tenth_peoples from "../../../../assets/testimonal/review_images.png";
import tenth_yellow_circle from "../../../../assets/testimonal/cicle-yellow.png";
import tenth_dot from "../../../../assets/testimonal/card-bg-dot.png";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import "./styles.css";

const Testimonial = () => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  const [disableRightArrow, setDisableRightArrow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const lastIndex = testimonials.length - 1;
  const handleLeftClick = () => {
    if (currentTestimonialIndex > 0) {
      setCurrentTestimonialIndex((val) => val - 1);
    }
  };

  const handleRightClick = () => {
    if (currentTestimonialIndex < lastIndex) {
      setCurrentTestimonialIndex((val) => val + 1);
    }
  };

  useEffect(() => {
    if (currentTestimonialIndex <= 0) {
      setDisableLeftArrow(true);
    } else {
      setDisableLeftArrow(false);
    }
    if (currentTestimonialIndex >= lastIndex) {
      setDisableRightArrow(true);
    } else {
      setDisableRightArrow(false);
    }
  }, [currentTestimonialIndex]);
  return (
    <div className="testimonial-container">
      <div className="testimonial-yellow-donut-wrap">
        <img
          className="testimonial-yellow-donut"
          src={tenth_yellow_circle}
          alt="yellow-donut"
        />
      </div>
      <div className="testimonial-wrap">
        <div className="bg-imgs-and-content-container">
          <img
            src={tenth_peoples}
            className="review-people-bg-img"
            alt="review-people"
          />
          <img
            className="white-dotted-bg-img"
            src={tenth_dot}
            alt="white-dotted-bg"
          />
          <div className="testimonial-card-main-container">
            <div className="testimonial-hd-wrap">
              <p className="testimonial-content-hd">What people say</p>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card-wrap">
                <div className="testimonial-text-container">
                  <div className="comma-bg-img">
                    <div className="testimonial-text-wrap">
                      <p className={`testimonial-text`}>
                        {testimonials[currentTestimonialIndex].text}
                      </p>
                    </div>
                    <div className="person-name-arrow-icon-wrap">
                      <p className="review-person-name">
                        {testimonials[currentTestimonialIndex].author}
                      </p>
                      <div className="arrow-icons-container">
                        <button
                          className={`left-arrow-icon-wrap ${
                            disableLeftArrow === true ? "disabled" : ""
                          }`}
                          onClick={handleLeftClick}
                        >
                          <FaArrowLeft className="left-arrow-icon" />
                        </button>

                        <button
                          className={`right-arrow-icon-wrap ${
                            disableRightArrow === true ? "right-disabled" : ""
                          }`}
                          onClick={handleRightClick}
                        >
                          <FaArrowRight className="right-arrow-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

const testimonials = [
  {
    text: "This is due to their excellent service, competitive pricing, and customer support. It's thoroughly refreshing to get such a personal touch.",
    author: "Jason Smith",
  },
  {
    text: "I've been extremely happy with their service. The team is professional, responsive, and always goes above and beyond to help.",
    author: "Emily Johnson",
  },
  {
    text: "Their products are top-notch, and their customer service is unparalleled. I highly recommend them!",
    author: "Michael Brown",
  },
  {
    text: "I've been a customer for years and have never been disappointed. Their attention to detail and commitment to quality are unmatched.",
    author: "Sarah Davis",
  },
  {
    text: "I can't say enough good things about them. From start to finish, they made the process easy and stress-free.",
    author: "Alex Wilson",
  },
  {
    text: "Their team is always friendly and helpful. They really care about their customers and it shows.",
    author: "Jessica Lee",
  },
  {
    text: "I've recommended them to all my friends and family. Once you try their service, you'll never go anywhere else.",
    author: "David Martinez",
  },
];
