import './App.css';
import Routing from '../src/routes/index';

function App() {
  return (
    <div className="App">
      <Routing/>
    </div>
  );
}

export default App;
