import React from "react";
import fourth_men from "../../../assets/muti-lingual/image.png";
import "./style.css"
import Button from "../landing-page-button";

const MultiLingual = () => {
  return (
    <div>
      <div className="multi-lingual-main-container">
        <div className="multi-lingual-wrap">
          <div className="multi-lingual-img-wrap" data-aos="fade-right">
            <img src={fourth_men} className="multi-lingual-img" />
          </div>
          <div className="multi-lingual-content-container" data-aos="fade-left">
            <div className="multi-lingual-hd-container">
              <p className="multi-lingual-hd">Multi-lingual book Adventures</p>
            </div>
            <div className="multi-lingual-text-container">
              <p className="multi-lingual-text">
                Discover the benefits of Al technology in language learning with
                FictionGenie. Kid can read captivating stories in any language
                of their choice and explore new horizons of culture and
                imagination.
              </p>
              <Button title="Learn More"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MultiLingual;
