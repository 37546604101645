import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "../components/newlandingpage/landing-page";

const Routing = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/landing-page" element={<LandingPage />} />
        </Routes>
      </Router>
    </div>
  );
};
export default Routing;
