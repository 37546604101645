import React from "react";
import "./style.css"

const Button = ({title}) => {
  return (
    <div className="middle">
            <button class="buttonfx slideleft">{title}</button>
    </div>
  );
};

export default Button;
